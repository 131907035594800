
.ps-tabs {
}

.ps-tabs ul {
	margin: auto;
	display: flex;
	list-style: none;
}
.ps-tabs ul li {
	text-align: center;
	background: #e9ecf1;
	border-right: 1px solid #c1c4c9;
	padding: 13px 15px;
	cursor: pointer;
	transition: all 0.2s ease;
}

.ps-tabs ul li:first-child {
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
}

.ps-tabs ul li:last-child {
	border-right: 0px;
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;
}

.ps-tabs ul li:hover,
.ps-tabs ul li.active {
	background: #7fc469;
	color: #fff;
}

